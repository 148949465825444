@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply py-2 px-4 rounded bg-white hover:bg-gray-100 text-gray-600 hover:text-gray-800 disabled:opacity-50;
}

.full-height, body, html, #root {
  height: calc(100vh - calc(100vh - 100%));
}

.btn-primary {
  @apply bg-red-600 text-white;
}

.btn-icon {
  @apply p-2;
}

.btn-icon svg {
  @apply align-bottom;
}

.timer-item-text {
  @apply text-2xl;
}

.timer-item {
  @apply flex gap-4 items-center py-4 mx-6 border-b border-gray-300 relative;
}

.timer-item--is-editing:before {
  /* @apply py-4 px-4 gap-4 bg-gray-300 -mt-px mx-2 rounded-xl; */
  /* content: "";
  @apply block absolute inset-0 py-4 px-4 gap-4 bg-blue-600 -my-px -mx-4 rounded-xl; */
}

.timer-item--is-editing > * {
  /* @apply relative */
}